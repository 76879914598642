import React, { useEffect } from 'react';
import { CheckList } from '../components';
import { Form, useFormikContext, withFormik } from 'formik';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useHistory } from 'react-router-dom';

const formikWrapper = withFormik({
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'About the Person - Other Details',
      action: 'Completed',
    });
    delete values.matomo;
    let next = '';
    if (
      Array.isArray(values.about_person.other_evidence) &&
      values.about_person.other_evidence.includes('Vehicle details')
    ) {
      next = 'vehicle-information';
    } else if (values.about_incident.report_type === 'A theft') {
      next = 'crime-reference';
    } else if (values.about_person.person_known === 'Yes') {
      next = 'buyer-information';
    } else {
      next = 'product-information';
    }
    values.sections.add('other-information');
    formikBag.props.updateValues(values, next);
  },
});

const OtherEvidence = ({ values, setFieldValue }) => {
  const formik = useFormikContext();
  const { trackPageView, trackEvent } = useMatomo();
  const history = useHistory();
  values.matomo = { trackEvent };
  useEffect(() => {
    trackPageView();
    trackEvent({
      category: 'About the Person - Other Details',
      action: 'Started',
    });

    window.scrollTo(0, 0);
    document.title = 'Other information - Report Suspicious Chemical Activity';
  }, []);
  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(
              `sections`,
              values.sections.delete(`other-information`)
            );
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-full">
            <Form autoComplete="on" onSubmit={formik.handleSubmit}>
              <div className="govuk-grid-column">
                <CheckList
                  name="about_person.other_evidence"
                  listValues={[
                    'CCTV',
                    'Receipts',
                    'Vehicle details',
                    'Documentation handled by the person (for fingerprinting)',
                  ]}
                  questionLarge="Have you captured any of the following about the incident?"
                  subText="Select all that apply."
                  hintId="other_evidence"
                />
              </div>
              <button
                type="submit"
                data-prevent-double-click="true"
                className="govuk-button"
                data-module="govuk-button"
                data-test={`button-continue`}
                aria-label={'Continue'}
              >
                Continue
              </button>
            </Form>
          </div>
        </div>
      </main>
    </div>
  );
};

const OtherEvidenceWithFormik = formikWrapper(OtherEvidence);

export { OtherEvidenceWithFormik as OtherEvidence };
