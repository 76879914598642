import React from 'react';
import { InputBox } from '../../components/Govuk';
import { generateUser } from './api';
import { Form, withFormik } from 'formik';
import * as Yup from 'yup';
import { EMAIL_ERROR_MSG } from '../OneOffSars/validation-utils';

const RegistrationSchema = Yup.object().shape({
  email: Yup.string().email(EMAIL_ERROR_MSG).required(EMAIL_ERROR_MSG),
});

const formikWrapper = withFormik({
  validationSchema: RegistrationSchema,
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, formikBag) => {
    generateUser(values.email)
      .then((res) => {
        if (res.status === 200) {
          formikBag.props.history.push('/sar-registration/complete');
        }
      })
      .catch((err) => {
        if (err.response.status === 406) {
          // not allowed
          formikBag.props.history.push('/sar-registration/not-allowed');
        } else {
          formikBag.props.history.push('/one-off-sars-error');
        }
      });
  },
});

const OneOffSarRegistration = () => {
  return (
    <div className="govuk-width-container">
      <main
        className="govuk-main-wrapper "
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <Form>
              <h1 className="govuk-heading-l" data-test="main-header-text">
                Link to a one-off report
              </h1>
              <p className="govuk-body" data-test="body-info-text">
                Once you provide your email address, we will email you a link to
                the one-off reporting form. You will need to enter your email
                address even if you have used this service before.
              </p>
              <p className="govuk-body" data-test="body-info-text">
                When you complete your form you will receive a copy of your
                report. Before you submit your answers, you will be able to add
                more emails if you would like other people or teams to also
                receive a copy of your report.
              </p>
              <InputBox
                name={'email'}
                label="Email address"
                subText="Provide an email address you can access easily."
              />
              <button
                id={'submit-email'}
                type="submit"
                data-prevent-double-click="true"
                className="govuk-button"
                data-module="govuk-button"
                aria-label="continue"
              >
                Continue
              </button>
            </Form>
          </div>
        </div>
      </main>
    </div>
  );
};

const OneOffSarRegistrationWithFormik = formikWrapper(OneOffSarRegistration);

export { OneOffSarRegistrationWithFormik as OneOffSarRegistration };
