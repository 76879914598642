import React, { useEffect } from 'react';
import { Form, useFormikContext, withFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { EPP_LICENSE_NUMBER_ERROR_MESSAGE } from '../validation-utils';
import { ErrorList } from '../components/ErrorList';
import * as Yup from 'yup';
import { InputBox } from '../components';

const EppLicenseSchema = Yup.object().shape({
  provider: Yup.object().shape({
    epp_license_number: Yup.string().optional(EPP_LICENSE_NUMBER_ERROR_MESSAGE),
  }),
});

const formikWrapper = withFormik({
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  validationSchema: EppLicenseSchema,
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'Epp License Number',
      action: 'Completed',
    });
    delete values.matomo;
    let next = 'about-incident';
    if (values.editMode) {
      next = 'review-answers';
    }
    values.sections.add('epp-license');
    formikBag.props.updateValues(values, next);
  },
});

const EppLicense = ({ setFieldValue, values }) => {
  const formik = useFormikContext();
  const history = useHistory();
  const { trackPageView, trackEvent } = useMatomo();
  formik.values.matomo = { trackEvent };

  useEffect(() => {
    trackPageView();
    trackEvent({
      category: 'Epp License',
      action: 'Started',
    });
    window.scrollTo(0, 0);
    document.title = 'Epp License - Report Suspicious Chemical Activity';
  }, []);

  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(`sections`, values.sections.delete(`epp-license`));
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-full">
            <ErrorList />
            <Form autoComplete="on" onSubmit={formik.handleSubmit}>
              <div className="govuk-grid-column">
                <InputBox
                  name="provider.epp_license_number"
                  hintId="epp_license_number"
                  subText={
                    'If easily accessible, enter your EPP license number'
                  }
                  questionLarge={
                    'Explosives Precursors and Poisons (EPP) license'
                  }
                />
              </div>
              <button
                type="submit"
                data-prevent-double-click="true"
                className="govuk-button"
                data-module="govuk-button"
                data-test={`button-continue`}
                aria-label={'Continue'}
              >
                Continue
              </button>
            </Form>
          </div>
        </div>
      </main>
    </div>
  );
};

const EppLicenseWithFormik = formikWrapper(EppLicense);

export { EppLicenseWithFormik as EppLicense };
