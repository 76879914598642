import React from 'react';
import axios from 'axios';
import './index.css';
import App from './App';
import AuthProvider from './components/auth/AuthContext';
import OneOffAuthProvider from './components/oneOffAuth/OneOffAuthContext';
import Keycloak from 'keycloak-js';
import { BrowserRouter as Router } from 'react-router-dom';
import { handleTokenExpiredEvent } from './components/auth/TokenUtils';
import { createInstance, MatomoProvider } from '@datapunt/matomo-tracker-react';
import { getConfig } from './Config';
import { createRoot } from 'react-dom/client';

const setUpApp = async () => {
  let keycloakConfig;
  if (window.ENV === 'Drone') {
    keycloakConfig = '/config/keycloak_drone.json';
  } else {
    keycloakConfig = '/config/keycloak.json';
  }
  const keycloak = Keycloak(keycloakConfig);
  const matomoSiteId = getConfig().matomoSiteId;
  const matomoUrl = getConfig().matomoUrl;
  keycloak.onAuthSuccess = async () => {
    updateSession();
  };

  const updateSession = () => {
    sessionStorage.setItem('token', keycloak.token);
    sessionStorage.setItem('idToken', keycloak.idToken);
    sessionStorage.setItem('tokenData', JSON.stringify(keycloak.tokenParsed));
    sessionStorage.setItem('authenticated', 'true');
  };

  axios.interceptors.request.use(async (request) => {
    if (keycloak.authenticated) {
      await keycloak.updateToken(300);
      updateSession();
    }
    const token = sessionStorage.getItem('token');
    if (token) {
      request.headers.Authorization = token;
    }
    return request;
  });

  axios.interceptors.response.use(async (response) => {
    if (response.status === 401) {
      keycloak.login();
    }
    return response;
  });

  const instance = createInstance(
    {
      urlBase: matomoUrl,
      siteId: matomoSiteId,
      trackerUrl: `${matomoUrl}/matomo.php`,
      srcUrl: `${matomoUrl}/matomo.js`,
      disabled: false,
      heartBeat: {
        active: true,
        seconds: 10,
      },
      linkTracking: false,
      configurations: {
        disableCookies: true,
        setSecureCookie: true,
        setRequestMethod: 'POST',
      },
    },
    [matomoSiteId, matomoUrl]
  );

  const main = () => {
    const root = createRoot(document.getElementById('root'));
    const token = sessionStorage.getItem('token');
    const refreshToken = sessionStorage.getItem('refreshToken'); // this is used only in Cypress tests

    const renderApp = () => {
      root.render(
        <MatomoProvider value={instance}>
          <AuthProvider keycloak={keycloak}>
            <OneOffAuthProvider>
              <Router>
                <App keycloak={keycloak} />
              </Router>
            </OneOffAuthProvider>
          </AuthProvider>
        </MatomoProvider>
      );
    };
    keycloak
      .init({
        flow: 'hybrid',
        checkLoginIframe: false,
        redirectUri: window.location,
        onLoad: 'check-sso',
        token,
        refreshToken,
      })
      .success((isAuthenticated) => {
        sessionStorage.setItem('authenticated', isAuthenticated.toString());
        renderApp();
      })
      .error(() => {
        sessionStorage.setItem('authenticated', 'false');
        renderApp();
      });
  };

  handleTokenExpiredEvent(keycloak);
  main();
};

await setUpApp();
