import React, { useEffect } from 'react';
import { TextArea } from '../components';
import { Form, useFormikContext, withFormik } from 'formik';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useHistory } from 'react-router-dom';

const formikWrapper = withFormik({
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'About the Incident - Suspicion',
      action: 'Completed',
    });
    delete values.matomo;
    let next = 'buyer-information';
    if (
      values.about_incident.report_type === 'A theft' ||
      values.about_incident.report_type === 'A disappearance'
    ) {
      next = 'product-information';
    }
    if (values.editMode) {
      next = 'review-answers';
    }
    values.sections.add('suspicion');
    formikBag.props.updateValues(values, next);
  },
});

const Suspicion = ({ values, setFieldValue }) => {
  const formik = useFormikContext();
  const { trackPageView, trackEvent } = useMatomo();
  const history = useHistory();
  values.matomo = { trackEvent };
  useEffect(() => {
    trackPageView();
    trackEvent({
      category: 'About the Incident - Suspicion',
      action: 'Started',
    });
    window.scrollTo(0, 0);
    document.title = 'Incident details - Report Suspicious Chemical Activity';
  }, []);
  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(`sections`, values.sections.delete(`suspicion`));
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <Form autoComplete="on" onSubmit={formik.handleSubmit}>
              <span className="govuk-caption-l">About the activity</span>
              <div className="govuk-grid-column">
                {(values.about_incident.report_type === 'A theft' ||
                  values.about_incident.report_type === 'A disappearance') && (
                  <TextArea
                    headingLarge="Do you have any reason to suspect this theft or disappearance was suspicious?"
                    name="about_incident.reason_suspicious"
                    subText={
                      <div>
                        <p>
                          The following examples may be helpful but do not cover
                          all potential reasons:
                        </p>

                        <ul>
                          <li>the time of day</li>
                          <li>the combination of products</li>
                          <li>the quantity of products</li>
                          <li>known information about the person</li>
                        </ul>
                      </div>
                    }
                    style="m"
                    hintId="suspicion_hint"
                  />
                )}
                {(values.about_incident.report_type ===
                  'A completed purchase' ||
                  values.about_incident.report_type ===
                    'An attempted purchase') && (
                  <TextArea
                    headingLarge="Why do you feel this activity was suspicious?"
                    name="about_incident.reason_suspicious"
                    subText={
                      <div>
                        <p>
                          The following examples may be helpful but do not cover
                          all potential reasons.
                        </p>
                        <p> Are they...</p>
                        <ul>
                          <li>your regular type of customer?</li>
                          <li>appearing nervous or avoiding communication?</li>
                          <li>
                            trying to buy an unusual amount of a product or a
                            strange combination of products?
                          </li>
                          <li>
                            unfamiliar with the product’s handling instructions?
                          </li>
                          <li>
                            refusing to buy a lower concentration product?
                          </li>
                          <li>
                            unwilling to share what they plan to use the product
                            for?
                          </li>
                          <li>insisting on paying in cash?</li>
                          <li>
                            unwilling to provide ID or home address details?
                          </li>
                          <li>
                            requesting unusual packing or delivery methods?
                          </li>
                        </ul>
                      </div>
                    }
                    style="m"
                    hintId="suspicion_hint"
                  />
                )}
              </div>
              <button
                type="submit"
                data-prevent-double-click="true"
                className="govuk-button"
                data-module="govuk-button"
                data-test={`button-continue`}
                aria-label={'Continue'}
              >
                Continue
              </button>
            </Form>
          </div>
        </div>
      </main>
    </div>
  );
};

const SuspicionWithFormik = formikWrapper(Suspicion);

export { SuspicionWithFormik as Suspicion };
