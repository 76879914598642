import React, { useEffect } from 'react';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { Form, useFormikContext, withFormik } from 'formik';
import { ErrorList } from '../components/ErrorList';
import { Enum, InputBox } from '../components';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import {
  PAYMENT_METHOD_REQUIRED,
  TRANSACTION_TYPE_OTHER_PAYMENT,
} from '../validation-utils';

const PaymentSchema = Yup.object().shape({
  transaction: Yup.object().shape({
    payment_method: Yup.string().required(PAYMENT_METHOD_REQUIRED),
  }),
});

const formikWrapper = withFormik({
  validationSchema: PaymentSchema,
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  validate: (values) => {
    const errors = {};
    if (values.transaction.payment_method === 'Other payment type') {
      if (values.transaction.other_payment.length === 0) {
        errors['transaction'] = {};
        errors['transaction']['other_payment'] = TRANSACTION_TYPE_OTHER_PAYMENT;
      }
    }
    return errors;
  },
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'Transaction Payment Method',
      action: 'Completed',
    });
    delete values.matomo;
    values.sections.add('payment-method');
    formikBag.props.updateValues(values, 'product-information');
  },
});

const PaymentMethod = ({ values, setFieldValue }) => {
  const formik = useFormikContext();
  const { trackPageView, trackEvent } = useMatomo();
  const history = useHistory();
  values.matomo = { trackEvent };

  useEffect(() => {
    trackPageView();
    trackEvent({
      category: 'Transaction Payment Method',
      action: 'Started',
    });
    window.scrollTo(0, 0);
    document.title = 'Payment method - Report Suspicious Chemical Activity';
  }, []);

  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(`sections`, values.sections.delete(`payment-method`));
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <ErrorList />
            <span className="govuk-caption-m">About the payment</span>
            <Form onSubmit={formik.handleSubmit}>
              <div className="govuk-grid-column">
                <Enum
                  enumValues={[
                    { value: 'No payment made' },
                    { value: 'Cash' },
                    { value: 'Credit or debit card' },
                    {
                      value: 'Other payment type',
                      conditionalName: 'transaction.other_payment',
                      conditionalLabel: 'Enter the type of payment',
                    },
                  ]}
                  name="transaction.payment_method"
                  direction="col"
                  questionLarge="Information about the transaction"
                  subText="What form of payment did the person use?"
                  values={values}
                />
                <InputBox
                  question="What other information can you tell us about the payment?"
                  name="transaction.payment_description"
                  subText={
                    <div>
                      <p>
                        {' '}
                        For example, payment ID details, invoice number and
                        credit card number.
                      </p>
                    </div>
                  }
                  style="m"
                  hintId="other-hint"
                />
              </div>
              <button
                type="submit"
                data-prevent-double-click="true"
                className="govuk-button"
                data-module="govuk-button"
                data-test={`button-continue`}
                aria-label={'Continue'}
              >
                Continue
              </button>
            </Form>
          </div>
        </div>
      </main>
    </div>
  );
};

const PaymentMethodWithFormik = formikWrapper(PaymentMethod);

export { PaymentMethodWithFormik as PaymentMethod };
