import React, { useEffect } from 'react';
import { withFormik } from 'formik';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import * as Yup from 'yup';
import { ErrorList } from '../components/ErrorList';
import { defaultProductValues } from '../initial-values';
import { useHistory } from 'react-router-dom';

const ProductInformationSchema = Yup.object().shape({
  product: Yup.object().shape({}),
});

const formikWrapper = withFormik({
  validationSchema: ProductInformationSchema,
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'Product Review',
      action: 'Completed',
    });
    delete values.matomo;
    values.sections.add(`product-review.${values.productIndex}`);

    let next = 'more-info';
    if (
      values.about_incident.report_type === 'A theft' ||
      values.about_incident.report_type === 'A disappearance'
    ) {
      next = 'more-info';
    }
    if (values.editMode) {
      next = 'review-answers';
    }

    formikBag.props.updateValues(values, next);
  },
});

const ProductReview = ({
  handleSubmit,
  values,
  formQuestions,
  updateValues,
}) => {
  const { trackPageView, trackEvent } = useMatomo();
  const history = useHistory();
  values.matomo = { trackEvent };

  useEffect(() => {
    trackPageView();
    trackEvent({
      category: 'Product Review',
      action: 'Started',
    });

    window.scrollTo(0, 0);
    document.title = 'Product added - Report Suspicious Chemical Activity';
  }, []);

  const onAddProduct = (e) => {
    e.preventDefault();
    const newIndex = values.products.length;
    const newProduct = Object.assign({}, defaultProductValues);
    newProduct.id = newIndex;
    values.productIndex = newIndex;
    values.products.push(newProduct);
    updateValues(values, 'product-information');
  };

  const onChangeProduct = (e, i) => {
    e.preventDefault();
    values.productIndex = i;
    updateValues(values, 'product-information');
  };

  const onRemoveProduct = (e, i) => {
    e.preventDefault();
    values.productRemoveIndex = i;
    updateValues(values, 'product-remove');
  };

  return (
    <div>
      <a className="govuk-back-link" href="#" onClick={() => history.goBack()}>
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <h1 className="govuk-label-wrapper">
              <label className="govuk-label govuk-label--l">
                Products involved
              </label>
            </h1>
            <p className="govuk-body">
              These are the products you listed for your report. You can change
              each item. You can also add another product below.
            </p>
            <ErrorList questions={formQuestions} />
            <form onSubmit={handleSubmit}>
              <dl className="govuk-summary-list govuk-!-margin-bottom-9">
                {values.products.map((p, i) => (
                  <div
                    className="govuk-summary-list__row"
                    key={`product-summary-${i}`}
                  >
                    <dt className="govuk-summary-list__key">{i + 1}</dt>
                    <dd
                      className="govuk-summary-list__value"
                      data-test="product-chemical-name"
                    >
                      Product: {p.product_name}
                    </dd>
                    <dd className="govuk-summary-list__actions">
                      <a
                        className="govuk-link"
                        href=""
                        onClick={(e) => onChangeProduct(e, i)}
                      >
                        Change
                        <span className="govuk-visually-hidden">
                          {' '}
                          {p.product_name}
                        </span>
                      </a>
                    </dd>
                    <dd className="govuk-summary-list__actions">
                      <a
                        className="govuk-link"
                        href=""
                        onClick={(e) => onRemoveProduct(e, i)}
                        data-test="product-remove"
                      >
                        Remove
                        <span className="govuk-visually-hidden">
                          {' '}
                          {p.product_name}
                        </span>
                      </a>
                    </dd>
                  </div>
                ))}
              </dl>
              <div>
                <button
                  data-prevent-double-click="true"
                  className="govuk-button govuk-button--secondary"
                  data-module="govuk-button"
                  data-test={`button-add`}
                  aria-label="add-another-product"
                  onClick={onAddProduct}
                >
                  Add another product
                </button>
              </div>
              <div>
                <button
                  type="submit"
                  data-prevent-double-click="true"
                  className="govuk-button"
                  data-module="govuk-button"
                  data-test={`button-continue`}
                  aria-label="continue"
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

const ProductReviewWithFormik = formikWrapper(ProductReview);

export { ProductReviewWithFormik as ProductReview };
