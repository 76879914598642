export const handleTokenExpiredEvent = (keycloak) => {
  keycloak.onTokenExpired = () => {
    keycloak
      .updateToken(300)
      .success(() => {
        sessionStorage.setItem('token', keycloak.token);
      })
      .error(() => {
        // If updating the token fails invalidate the session to force login when user performs an action
        keycloak.clearToken();
        keycloak.init({
          promiseType: 'native',
          checkLoginIframe: false,
        });
      });
  };
};
